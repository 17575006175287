.square {
  width: 100%;
  aspect-ratio: 4/5;
  object-fit: cover;
}
.pin-icon {
  filter: invert(1) brightness(2000);
}
.rectangle {
  width: 100%;
  aspect-ratio: 9/16;
  object-fit: cover;
}
