@tailwind base;
@tailwind components;
@tailwind utilities;

body, pre {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #010101;
  /* background-color: #121212; */
  font-size: 13px;
  color: #fff;
  text-align: left;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; /* Disable callout */
    -webkit-user-select: none; /* Disable selection */
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.image {
  object-fit: cover;
  -webkit-user-select:none;
  -webkit-touch-callout:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
